<template>
  <div :class="['product', $store.state.lang !== 'zh' ? 'product_en' : '']">
    <div class="product-title">
      <p class="product-title-1" @click="jump('product-spec')">{{ $t('product.msg_1') }}</p>
      <p class="product-title-2">BR-W950</p>
    </div>
    <div class="product-img">
      <img class="product-img-1" src="../../assets/images/words@2x.png" alt="">
    </div>
    <!-- p0-->
    <section class="product-0">
      <img class="product-0-img" src="../../assets/images/product-p1_pic@2x.png" alt="">
      <div class="product-0-title animate__animated animate__fadeInDown">BR-W950</div>
    </section>
    <!-- p1-->
    <section class="product-1">
      <!-- p1 desc 1 -->
      <div class="product-1-detail animate__animated animate__fadeInDown">
        <div class="detail-title">
          <p>{{ $t('product.msg_2') }}</p>
        </div>
        <div class="detail-desc">
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_3') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_4') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_5') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
        </div>
      </div>
      <!-- p1 desc 2 -->
      <div class="product-1-detail animate__animated animate__fadeInDown">
        <div class="detail-title">
          <p>{{ $t('product.msg_6') }}</p>
        </div>
        <div class="detail-desc">
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_7') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_8') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_9') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
        </div>
      </div>
      <!-- p1 desc 1 -->
      <div class="product-1-detail animate__animated animate__fadeInDown">
        <div class="detail-title">
          <p>{{ $t('product.msg_10') }}</p>
        </div>
        <div class="detail-desc">
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_11') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
          <p>
            <span class="detail-desc-icon-left">&lt;</span>
            {{ $t('product.msg_12') }}
            <span class="detail-desc-icon-right">&gt;</span>
          </p>
        </div>
      </div>
    </section>
    <!-- p2 -->
    <section class="product-2" id="product-2">
      <div id="product-2-title" class="product-2-title animate__animated monkey-hide">
        <p><span class="product-2-title-icon">/</span>{{ $t('product.msg_13') }}</p>
      </div>
      <div id="product-2-desc" class="product-2-desc animate__animated monkey-hide">
        <p><span class="product-2-desc-icon">//</span>{{ $t('product.msg_14') }}</p>
      </div>
      <div id="product-2-word" class="product-2-word animate__animated monkey-hide">
        <p><span class="product-2-word-icon">&lt;</span>{{ $t('product.msg_15') }}</p>
      </div>
      <div id="product-2-media" class="product-2-video animate__animated monkey-hide">
        <div id="product-2-video"></div>
      </div>
      <div id="product-2-img" class="product-2-img animate__animated monkey-hide">
        <img class="product-2-img-1" src="../../assets/images/product-p2_icons@2x.png" alt="">
        <img class="product-2-img-2" src="../../assets/images/phone-product-p2_icons@2x.png" alt="">
      </div>
      <div id="product-2-extra" class="product-2-extra animate__animated monkey-hide">
        <p class="product-2-extra-word">{{ $t('product.msg_16') }}</p>
        <p class="product-2-extra-word">{{ $t('product.msg_17') }}</p>
        <p class="product-2-extra-word">{{ $t('product.msg_18') }}</p>
      </div>
    </section>
    <!-- p3 -->
    <section id="product-3" class="product-3">
      <div class="product-3-title">
        <p><span class="product-3-title-icon">/</span>{{ $t('product.msg_19') }}</p>
      </div>
      <div id="product-3-video" class="product-3-video animate__animated monkey-hide">
        <div class="product-3-video-wrapper">
          <div id="product-3-video-1" v-show="p3Active === 1"></div>
          <div id="product-3-video-2" v-show="p3Active === 2"></div>
        </div>
        <div class="product-3-video-button">
          <div :class="['button-item', 'button-item-1', p3Active === 1 ? 'button-active' : '']" @click="changeP3Active(1)">{{ $t('product.msg_20') }}</div>
          <div :class="['button-item', 'button-item-2', p3Active === 2 ? 'button-active' : '']" @click="changeP3Active(2)">{{ $t('product.msg_21') }}</div>
        </div>
      </div>
      <div class="product-3-desc">
        <p v-if="p3Active === 1">{{ $t('product.msg_22') }}</p>
        <p v-if="p3Active === 2">{{ $t('product.msg_23') }}</p>
      </div>
      <div class="product-3-word">
        <p v-if="p3Active === 1">{{ $t('product.msg_24') }}</p>
        <p v-if="p3Active === 2">{{ $t('product.msg_25') }}</p>
      </div>
    </section>
    <!-- p4 -->
    <section id="product-4" class="product-4">
      <div class="product-4-title">
        <p><span class="product-4-title-icon">/</span>{{ $t('product.msg_26') }}</p>
      </div>
      <div id="product-4-video" class="product-4-video animate__animated monkey-hide">
        <div class="product-4-video-wrapper">
          <div id="product-4-video-1" v-show="p4Active === 1"></div>
          <div id="product-4-video-2" v-show="p4Active === 2"></div>
        </div>
        <div class="product-4-video-button">
          <div :class="['button-item', p4Active === 1 ? 'button-active' : '']" @click="changeP4Active(1)">{{ $t('product.msg_27') }}</div>
          <div :class="['button-item', p4Active === 2 ? 'button-active' : '']" @click="changeP4Active(2)">{{ $t('product.msg_28') }}</div>
        </div>
      </div>
      <div class="product-4-desc">
        <p v-if="p4Active === 1">{{ $t('product.msg_29') }}</p>
        <p v-if="p4Active === 2">{{ $t('product.msg_30') }}</p>
      </div>
      <div class="product-4-word">
        <p v-if="p4Active === 1">{{ $t('product.msg_31') }}</p>
        <p v-if="p4Active === 2">{{ $t('product.msg_32') }}</p>
      </div>
    </section>
    <!-- p5 -->
    <section id="product-5" class="product-5">
      <div id="product-5-title" class="product-5-title animate__animated monkey-hide">
        <p><span class="product-5-title-icon">/</span>{{ $t('product.msg_33') }}</p>
      </div>
      <div id="product-5-desc" class="product-5-desc animate__animated monkey-hide">
        <p><span class="product-5-desc-icon">//</span>{{ $t('product.msg_34') }}</p>
      </div>
      <div id="product-5-word" class="product-5-word animate__animated monkey-hide">
        <p><span class="product-5-word-icon">&lt;</span>{{ $t('product.msg_35') }}</p>
      </div>
      <div id="product-5-img" class="product-5-img animate__animated monkey-hide">
        <img class="product-5-img-1" src="../../assets/images/product-p7_pic@2x.png" alt="">
      </div>
    </section>
    <!-- p6 -->
    <section id="product-6" class="product-6 animate__animated monkey-hide">
      <div class="product-6-title">
        <p><span class="product-6-title-icon">/</span>{{ $t('product.msg_36') }}</p>
      </div>
      <div class="product-6-desc">
        <p><span class="product-6-desc-icon">//</span>{{ $t('product.msg_37') }}</p>
      </div>
      <div class="product-6-word">
        <p><span class="product-6-word-icon">&lt;</span>{{ $t('product.msg_38') }}</p>
      </div>
      <div class="product-6-img">
        <img class="product-6-img-1" src="../../assets/images/product-p8_pic@2x.png" alt="">
      </div>
    </section>
    <!-- p7 -->
    <section id="product-7" class="product-7 animate__animated monkey-hide">
      <div class="product-7-title">
        <p><span class="product-7-title-icon">/</span>{{ $t('product.msg_39') }}</p>
      </div>
      <div class="product-7-desc">
        <p><span class="product-7-desc-icon">//</span>{{ $t('product.msg_40') }}</p>
      </div>
      <div class="product-7-word">
        <p><span class="product-7-word-icon">&lt;</span>{{ $t('product.msg_41') }}</p>
      </div>
      <div class="product-7-img">
        <img class="product-7-img-1" src="../../assets/images/product-s9_pic_app@2x.png" alt="">
      </div>
    </section>
  </div>
</template>

<script>
import Player from 'xgplayer'

export default {
  data () {
    return {
      p2Player: null, // 会车礼让
      p2Action: false,
      p3Active: 1,
      p3Player1: null, // 自动
      p3Player2: null, // 手动
      p3Action: false,
      p4Active: 1,
      p4Player1: null, // 自动
      p4Player2: null, // 手动
      p4Action: false,
      p5Action: false,
      p6Action: false,
      p7Action: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 会车礼让
      this.initVedioPro2 ()
      // 跨越减速带
      this.initVideoPro3V1 ()
      // 跨越斜坡
      this.initVideoPro3V2 ()
      // 自动
      this.initVideoPro4V1()
      // 手动
      this.initVideoPro4V2()
    })
     window.addEventListener('scroll', this.viewScroll, true)
  },
  methods: {
    viewScroll () {
      // p2动画效果
      const watchDom1 = document.getElementById('product-2')
      const screenHeight = window.screen.height
      const screenWidth = window.screen.width
      const isSmall = screenWidth < 800
      const height1 = watchDom1.getBoundingClientRect().top

      if (height1 < screenHeight / 2 && !this.p2Action) {
        const dom1 = document.getElementById('product-2-title')
        const dom2 = document.getElementById('product-2-desc')
        const dom3 = document.getElementById('product-2-word')
        const dom4 = document.getElementById('product-2-media')
        const dom5 = document.getElementById('product-2-img')
        const dom6 = document.getElementById('product-2-extra')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom2.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom3.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom4.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom5.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom6.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom2.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom3.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom4.classList.replace('monkey-hide', 'animate__bounceInRight')
          dom5.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom6.classList.replace('monkey-hide', 'animate__bounceInLeft')
        }
        this.p2Action = true
      }
      // p3动画效果
      const watchDom2 = document.getElementById('product-3')
      const height2 = watchDom2.getBoundingClientRect().top
      if (height2 < screenHeight / 2 && !this.p3Action) {
        const dom1 = document.getElementById('product-3-video')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInLeft')
        }
        this.p3Action = true
      }
      // p4动画效果
      const watchDom3 = document.getElementById('product-4')
      const height3 = watchDom3.getBoundingClientRect().top
      if (height3 < screenHeight / 2 && !this.p4Action) {
        const dom1 = document.getElementById('product-4-video')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInLeft')
        }
        this.p4Action = true
      }
      // p5动画效果
      const watchDom5 = document.getElementById('product-5')
      const height5 = watchDom5.getBoundingClientRect().top
      if (height5 < screenHeight / 2 && !this.p5Action) {
        const dom1 = document.getElementById('product-5-title')
        const dom2 = document.getElementById('product-5-desc')
        const dom3 = document.getElementById('product-5-word')
        const dom4 = document.getElementById('product-5-img')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom2.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom3.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom4.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom2.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom3.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom4.classList.replace('monkey-hide', 'animate__bounceInRight')
        }
        this.p5Action = true
      }
      // p6动画效果
      const watchDom6 = document.getElementById('product-6')
      const height6 = watchDom6.getBoundingClientRect().top
      if (height6 < screenHeight / 2 && !this.p6Action) {
        const dom1 = document.getElementById('product-6')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInLeft')
        }
        this.p6Action = true
      }
      // p7动画效果
      const watchDom7 = document.getElementById('product-7')
      const height7 = watchDom7.getBoundingClientRect().top
      if (height7 < screenHeight / 2 && !this.p7Action) {
        const dom1 = document.getElementById('product-7')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInRight')
        }
        this.p7Action = true
      }
    },
    // 跳转
    jump(path) {
      this.$router.push({
        path: '/' + path
      })
    },
    // 会车礼让
    initVedioPro2 () {
      this.p2Player = new Player({
        el: document.querySelector('#product-2-video'),
        url: 'https://dl.botifyrobot.com/website/video/GIVE_WAY.mp4',
        poster: 'https://dl.botifyrobot.com/website/video/GIVE_WAY.png',
        fluid: true
      })
    },
    changeP3Active (index) {
      this.p3Active = index
      if (index === 1) {
        this.p3Player2.pause()
      } else {
        this.p3Player1.pause()
      }
    },
    // 跨越减速带
    initVideoPro3V1 () {
      this.p3Player1 = new Player({
        el: document.querySelector('#product-3-video-1'),
        url: 'https://dl.botifyrobot.com/website/video/PASS_SPEED_BUMP.mp4',
        poster: 'https://dl.botifyrobot.com/website/video/PASS_SPEED_BUMP.png',
        fluid: true
      })
    },
     // 跨越斜坡
    initVideoPro3V2 () {
      this.p3Player2 = new Player({
        el: document.querySelector('#product-3-video-2'),
        url: 'https://dl.botifyrobot.com/website/video/PASS_SLOWDOWN_ZONE.mp4',
        poster: 'https://dl.botifyrobot.com/website/video/PASS_SLOWDOWN_ZONE.png',
        fluid: true
      })
    },
    changeP4Active (index) {
      this.p4Active = index
      if (index === 1) {
        this.p4Player2.pause()
      } else {
        this.p4Player1.pause()
      }
    },
    // 自动
    initVideoPro4V1 () {
      this.p4Player1 = new Player({
        el: document.querySelector('#product-4-video-1'),
        url: 'https://dl.botifyrobot.com/website/video/MODE_AUTO.mp4',
        poster: 'https://dl.botifyrobot.com/website/video/MODE_AUTO.png',
        fluid: true
      })
    },
    // 手动
    initVideoPro4V2 () {
      this.p4Player2 = new Player({
        el: document.querySelector('#product-4-video-2'),
        url: 'https://dl.botifyrobot.com/website/video/MODE_MANUAL.mp4',
        poster: 'https://dl.botifyrobot.com/website/video/MODE_MANUAL.png',
        fluid: true
      })
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .product {
    .product-title {
      font-size: .22rem;
      color: #C0C2C4;
      height: .6rem;
      line-height: .6rem;
      background-color: #31363c;
      width: 100%;
      .product-title-1 {
        cursor: pointer;
        display: inline-block;
        float: right;
        margin-right: 1.5rem;
      }
      .product-title-1:hover {
        color: #006DA0;
      }
      .product-title-2 {
        cursor: pointer;
        display: inline-block;
        float: right;
        margin-right: .6rem;
        color: #006DA0;
      }
    }

    // 图片
    .product-img {
      height: 1.22rem;
      position: relative;

      .product-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: -.28rem;
        left: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // p0
    .product-0 {
      height: 7.21rem;
      width: 100%;
      position: relative;
      .product-0-img {
        width: 7.79rem;
        height: 7.21rem;
        margin: 0 auto;
        display: block;
      }

      .product-0-title {
        display: block;
        width: 100%;
        font-size: 1rem;
        color: #F2F3F4;
        text-align: center;
        position: absolute;
        top: .34rem;
      }
    }

    // p1
    .product-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .product-1-detail {
        text-align: center;
        .detail-title {
          font-size: .28rem;
          color: #F2F3F4;
          font-family: 'SourceHanSansCNBold';
          line-height: .6rem;
        }

        .detail-desc {
          color: #F2F3F4;
          font-size: .16rem;
          line-height: .56rem;

          .detail-desc-icon-left {
            padding-right: .1rem;
          }

          .detail-desc-icon-right {
            padding-left: .1rem;
          }
        }
      }
    }

    // p2
    .product-2 {
      padding-left: 2.57rem;
      min-height: 5.87rem;
      margin-bottom: 1.5rem;
      margin-top: 1.6rem;
      position: relative;

      .product-2-title {
        padding-top: .3rem;
        width: 4.43rem;
        font-size: .38rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-2-title-icon {
          padding-right: .2rem;
        }
      }

      .product-2-desc {
        width: 4.43rem;
        font-size: .28rem;
        color: #C0C2C4;
        margin: .45rem 0;

        .product-2-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-2-word {
        width: 4.43rem;
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem 0;
        line-height: .42rem;

        .product-2-word-icon {
          padding-right: .2rem;
        }
      }

      .product-2-img {
        width: 4.54rem;
        height: 1.16rem;

        .product-2-img-1 {
          width: 100%;
          height: 100%;
        }

        .product-2-img-2 {
          display: none;
         
        }
      }

      .product-2-extra {
        font-size: .16rem;
        color: #C0C2C4;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 5rem;
        margin-top: .3rem;
        margin-left: -.25rem;

        .product-2-extra-word {
          display: block;
          text-align: center;
          flex: 1;
        }
      }

      .product-2-video {
        width: 10.42rem;
        height: 5.87rem;
        position: absolute;
        right: .7rem;
        top: 0;
      }
    }

    // p3
    .product-3 {
      margin-bottom: 1.5rem;

      .product-3-title {
        padding-top: 2rem;
        padding-left: 2.57rem;
        font-size: .38rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-3-title-icon {
          padding-right: .2rem;
        }
      }

      .product-3-video {
        width: 14.05rem;
        margin: .4rem auto 0 auto;

        .product-3-video-wrapper {
          width: 14.05rem;
          height: 7.9rem;
        }

        .product-3-video-button {
          width: 5.2rem;
          height: .76rem;
          margin: .39rem auto;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .button-item {
            width: 2.4rem;
            height: .76rem;
            line-height: .76rem;
            text-align: center;
            background: #262C39;
            color: #C0C2C4;
            font-size: .28rem;
            cursor: pointer;
          }

          .button-active {
            background: #006B9C;
            color: #F0F2F4;
          }
        }
      }

      .product-3-desc {
        font-size: .28rem;
        color: #C0C2C4;
        margin: .45rem 0;
        text-align: center;
      }

      .product-3-word {
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem 0;
        line-height: .42rem;
        text-align: center;
      }
    }

    // p4
    .product-4 {
      margin-bottom: 1.5rem;

      .product-4-title {
        padding-top: 2rem;
        padding-left: 2.57rem;
        font-size: .38rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-4-title-icon {
          padding-right: .2rem;
        }
      }

      .product-4-video {
        width: 14.05rem;
        margin: .4rem auto 0 auto;

        .product-4-video-wrapper {
          width: 14.05rem;
          height: 7.9rem;
        }

        .product-4-video-button {
          width: 5.2rem;
          height: .76rem;
          margin: .39rem auto;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .button-item {
            width: 2.4rem;
            height: .76rem;
            line-height: .76rem;
            text-align: center;
            background: #262C39;
            color: #C0C2C4;
            font-size: .28rem;
            cursor: pointer;
          }

          .button-active {
            background: #006B9C;
            color: #F0F2F4;
          }
        }
      }

      .product-4-desc {
        font-size: .28rem;
        color: #C0C2C4;
        margin: .45rem 0;
        text-align: center;
      }

      .product-4-word {
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem 0;
        line-height: .42rem;
        text-align: center;
      }
    }

    // p5
    .product-5 {
      padding-left: 2.57rem;
      height: 9.07rem;
      margin-bottom: 1.5rem;
      position: relative;

      .product-5-title {
        padding-top: 3.3rem;
        width: 4.43rem;
        font-size: .38rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-5-title-icon {
          padding-right: .2rem;
        }
      }

      .product-5-desc {
        width: 4.43rem;
        font-size: .28rem;
        color: #C0C2C4;
        margin: .45rem 0;

        .product-5-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-5-word {
        width: 4.43rem;
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem 0;
        line-height: .42rem;

        .product-5-word-icon {
          padding-right: .2rem;
        }
      }

      .product-5-img {
        width: 10.42rem;
        height: 9.07rem;
        position: absolute;
        right: .7rem;
        top: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // p6
    .product-6 {
      padding-left: 2.57rem;

      .product-6-title {
        font-size: .38rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-6-title-icon {
          padding-right: .2rem;
        }
      }

      .product-6-desc {
        font-size: .28rem;
        color: #C0C2C4;
        margin: .45rem 0;

        .product-6-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-6-word {
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem 0;
        line-height: .42rem;

        .product-6-word-icon {
          padding-right: .2rem;
        }
      }

      .product-6-img {
        width: 14.06rem;
        height: 7.91rem;
        margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // p7
    .product-7 {
      padding-left: 2.57rem;

      .product-7-title {
        font-size: .38rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-7-title-icon {
          padding-right: .2rem;
        }
      }

      .product-7-desc {
        font-size: .28rem;
        color: #C0C2C4;
        margin: .45rem 0;

        .product-7-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-7-word {
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem 0;
        line-height: .42rem;

        .product-7-word-icon {
          padding-right: .2rem;
        }
      }

      .product-7-img {
        width: 16.63rem;
        height: 7.64rem;
        margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .product_en {
    .product-3-video-button {
      width: 8rem!important;
    }
    .button-item-1 {
      width: 4rem!important;
    }
    .button-item-2 {
      width: 3rem!important;
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .product {
    .product-title {
      font-size: .22rem;
      color: #C0C2C4;
      height: .8rem;
      line-height: .8rem;
      background-color: #31363c;
      width: 100%;
      .product-title-1 {
        display: inline-block;
        float: right;
        margin-right: .4rem;
        cursor: pointer;
      }
      .product-title-1:hover {
        color: #006DA0;
      }
      .product-title-2 {
        cursor: pointer;
        display: inline-block;
        float: left;
        margin-left: .4rem;
        color: #006DA0;
      }
    }

    // 图片
    .product-img {
      .product-img-1 {
        width: .98rem;
        height: 1.16rem;
        margin-left: .4rem;
        margin-top: .13rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // p0
    .product-0 {
      height: 5.52rem;
      margin: 0 auto;
      position: relative;
      .product-0-img {
        width: 5.96rem;
        height: 5.52rem;
        margin: 0 auto;
        display: block;
      }

      .product-0-title {
        display: block;
        width: 100%;
        font-size: .72rem;
        color: #F2F3F4;
        text-align: center;
        position: absolute;
        top: .4rem;
      }
    }

    // p1
    .product-1 {
      .product-1-detail {
        text-align: center;
        .detail-title {
          padding-top: .73rem;
          padding-bottom: .3rem;
          font-size: .28rem;
          color: #F2F3F4;
          font-family: 'SourceHanSansCNBold';
        }

        .detail-desc {
          color: #F2F3F4;
          font-size: .22rem;
          line-height: .47rem;

          .detail-desc-icon-left {
            padding-right: .1rem;
          }

          .detail-desc-icon-right {
            padding-left: .1rem;
          }
        }
      }
    }

    // p2
    .product-2 {
      margin: 0 auto;

      .product-2-title {
        margin: 0 auto;
        padding-top: .3rem;
        width: 6.71rem;
        font-size: .42rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-2-title-icon {
          padding-right: .2rem;
        }
      }

      .product-2-desc {
        width: 6.71rem;
        font-size: .28rem;
        line-height: .5rem;
        color: #C0C2C4;
        margin: .45rem auto;

        .product-2-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-2-word {
        width: 6.71rem;
        font-size: .22rem;
        color: #C0C2C4;
        margin: .45rem auto;
        line-height: .48rem;

        .product-2-word-icon {
          padding-right: .2rem;
        }
      }

      .product-2-img {
        width: 6.03rem;
        height: 1.16rem;
        margin: .56rem auto .28rem auto;

        .product-2-img-1 {
          display: none;
        }

        .product-2-img-2 {
          width: 100%;
          height: 100%;
        }
      }

      .product-2-extra {
        font-size: .22rem;
        color: #C0C2C4;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width:100%;

        .product-2-extra-word {
          display: block;
          text-align: center;
          flex: 1;
          line-height: .3rem;
        }
      }

      .product-2-video {
        width: 6.71rem;
        height: 3.78rem;
        margin: 0 auto;
      }
    }

    // p3
    .product-3 {
      .product-3-title {
        width: 6.7rem;
        margin: 0 auto;
        padding-top: .92rem;
        font-size: .42rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-3-title-icon {
          padding-right: .2rem;
        }
      }

      .product-3-video {
        width: 6.7rem;
        margin: .4rem auto 0 auto;

        .product-3-video-wrapper {
          width: 6.7rem;
          height: 3.77rem;
        }

        .product-3-video-button {
          width: 5.2rem;
          height: .76rem;
          margin: .39rem auto;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .button-item {
            width: 2.4rem;
            height: .76rem;
            line-height: .76rem;
            text-align: center;
            background: #262C39;
            color: #C0C2C4;
            font-size: .22rem;
            cursor: pointer;
          }

          .button-active {
            background: #006B9C;
            color: #F0F2F4;
          }
        }
      }

      .product-3-desc {
        width: 6.7rem;
        font-size: .28rem;
        color: #F0F2F4;
        margin: .56rem auto .2rem auto;
        text-align: center;
        line-height: .5rem;
      }

      .product-3-word {
        width: 6.7rem;
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem auto;
        line-height: .48rem;
        text-align: center;
      }
    }

    // p4
    .product-4 {
      margin-bottom: 1.5rem;

      .product-4-title {
        width: 6.7rem;
        margin: 0 auto;
        padding-top: .92rem;
        font-size: .42rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-4-title-icon {
          padding-right: .2rem;
        }
      }

      .product-4-video {
        width: 6.7rem;
        margin: .4rem auto 0 auto;

        .product-4-video-wrapper {
          width: 6.7rem;
          height: 3.77rem;
        }

        .product-4-video-button {
          width: 5.2rem;
          height: .76rem;
          margin: .39rem auto;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .button-item {
            width: 2.4rem;
            height: .76rem;
            line-height: .76rem;
            text-align: center;
            background: #262C39;
            color: #C0C2C4;
            font-size: .22rem;
            cursor: pointer;
          }

          .button-active {
            background: #006B9C;
            color: #F0F2F4;
          }
        }
      }

      .product-4-desc {
        width: 6.7rem;
        font-size: .28rem;
        color: #F0F2F4;
        line-height: .5rem;
        margin: .56rem auto .2rem auto;
        text-align: center;
      }

      .product-4-word {
        width: 6.7rem;
        font-size: .16rem;
        color: #C0C2C4;
        margin: .45rem auto;
        line-height: .48rem;
        text-align: center;
      }
    }

    // p5
    .product-5 {
      margin-bottom: 1.5rem;

      .product-5-title {
        width: 6.7rem;
        margin: 0 auto;
        font-size: .42rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-5-title-icon {
          padding-right: .2rem;
        }
      }

      .product-5-desc {
        width: 6.7rem;
        margin: .45rem auto;
        font-size: .28rem;
        color: #C0C2C4;
        line-height: .5rem;

        .product-5-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-5-word {
        width: 6.7rem;
        margin: .45rem auto;
        font-size: .22rem;
        color: #C0C2C4;
        line-height: .48rem;

        .product-5-word-icon {
          padding-right: .2rem;
        }
      }

      .product-5-img {
        width: 6.7rem;
        height: 5.8rem;
        margin: .45rem auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // p6
    .product-6 {
      .product-6-title {
        width: 6.7rem;
        margin: 0 auto;
        font-size: .42rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-6-title-icon {
          padding-right: .2rem;
        }
      }

      .product-6-desc {
        width: 6.7rem;
        margin: .45rem auto;
        font-size: .28rem;
        color: #C0C2C4;
        line-height: .5rem;

        .product-6-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-6-word {
        width: 6.7rem;
        margin: .45rem auto;
        font-size: .22rem;
        color: #C0C2C4;
        line-height: .48rem;

        .product-6-word-icon {
          padding-right: .2rem;
        }
      }

      .product-6-img {
        width: 6.7rem;
        height: 3.77rem;
         margin: 0 auto;
        margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // p7
    .product-7 {
      .product-7-title {
        width: 6.7rem;
        margin: 0 auto;
        font-size: .42rem;
        color: #F0F2F4;
        font-family: 'SourceHanSansCNBold';

        .product-7-title-icon {
          padding-right: .2rem;
        }
      }

      .product-7-desc {
        width: 6.7rem;
        margin: .45rem auto;
        font-size: .28rem;
        color: #C0C2C4;
        line-height: .5rem;

        .product-7-desc-icon {
          padding-right: .2rem;
        }
      }

      .product-7-word {
        width: 6.7rem;
        margin: .45rem auto;
        font-size: .22rem;
        color: #C0C2C4;
        line-height: .48rem;

        .product-7-word-icon {
          padding-right: .2rem;
        }
      }

      .product-7-img {
        width: 7.1rem;
        height: 3.18rem;
        margin-bottom: 1.5rem;
        padding-left: .4rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .product_en {
    .product-3-video-button {
      width: 5rem!important;
    }
    .button-item-1 {
      width: 2.8rem!important;
    }
    .button-item-2 {
      width: 1,8rem!important;
    }
  }
}
</style>