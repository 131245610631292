import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2922b364&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=2922b364&prod&lang=less&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-co_v6mwpao77rocnmghkwxodgekju/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports